.hexa-video {
  object-fit: cover;
  height: 97vh;
  width: 100vw;
}
.school-hero {
  position: absolute;
  margin: -37% 40px 0 0px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 30px;
  width: 100%;
}
.school-title {
  grid-column: 2/7;
  display: flex;
  align-items: center;
}
.school-title img {
  width: 30%;
}
.school-title h1 {
  color: #1e0606;
  float: right;
  margin-top: 10%;
}
.info-backgound {
  background: linear-gradient(
      270deg,
      #ffffff 4.75%,
      rgba(255, 255, 255, 0) 95.88%
    ),
    url(../../../public/img/hegagonoscompleto.jpg);
  background-size: cover;
  min-height: 97vh;
  width: 100vw;
  margin-bottom: -370px;
}
.inscription-backgound {
  background: linear-gradient(
      270deg,
      #ffffff 4.75%,
      rgba(255, 255, 255, 0) 95.88%
    ),
    url(../../../public/img/hegagonoscompleto.jpg);
  background-size: cover;
  min-height: 97vh;
  width: 100vw;
  margin-bottom: -370px;
}
.push {
  height: 366px;
}
.program-backgound {
  background: linear-gradient(
      270deg,
      #ffffff 4.75%,
      rgba(255, 255, 255, 0) 95.88%
    ),
    url(../../../public/img/hegagonoscompleto.jpg);
  background-size: cover;
  min-height: 97vh;
  width: 100vw;
  margin-bottom: -370px;
}
.info-hero {
  min-height: 85vh;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 30px;
  row-gap: 16px;
  margin: 0px 40px 0 40px;
  padding-top: 100px;
  grid-template-rows: auto;
}
.info-text {
  grid-column: 4/8;
  color: #1e0606;
  width: 100%;
  font-size: 0.8em;
  columns: 2;
  margin-bottom: 60px;
}
.items {
  grid-column: 2/5;
}
.info-block {
  grid-column: 2/5;
}
.school-home-link h5 {
  color: #949292;
  font-size: 14px;
}
.info-title {
  color: #9b2743;
  font-size: 30px;
  font-weight: 300;
  margin-top: 5px;
}
.courses-block {
  grid-column: 2/6;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
.course-top {
  border-bottom: 1px solid #d8cece;
  padding-bottom: 2px;
  margin-bottom: 4px;
}
.course-date {
  display: inline;
  color: #9b2743;
  font-size: 16px;
}
.course-state {
  float: right;
  color: #9b2743;
  font-size: 12px;
  font-weight: 500;
  margin-top: 3px;
}
.item-school {
  margin: 0;
  font-family: 'Sora', sans-serif;
  font-size: 15px;
  color: #1e0606;
  font-weight: 300;
  padding-right: 8px;
  margin-right: 8px;
  justify-content: center;
}
.item-school-selected {
  margin: 0;
  font-family: 'Sora', sans-serif;
  font-size: 15px;
  color: #1e0606;
  font-weight: 800;
  border-right: 1.5px solid #d8cece;
  padding-right: 8px;
  margin-right: 8px;
}
.video-youtube {
  grid-column: 6/8;
  margin-bottom: 11%;
}
.program-title {
  grid-column: 2/6;
}
.program-block {
  grid-column: 2/4;
}
.program-block h4 {
  color: #9b2743;
}
.program-list {
  margin-top: 5px;
  font-weight: 400;
  font-size: 0.8em;
}
.program-subtitle {
  margin-top: 10px;
}
.program-block2 {
  grid-column: 4/6;
}
.program-block2 h4 {
  color: #9b2743;
}
.program-buttons {
  margin-top: 26px;
  padding-bottom: 18%;
}
.item-program {
  margin: 0;
  font-family: 'Sora', sans-serif;
  font-size: 15px;
  color: #1e0606;
  font-weight: 300;
  padding-right: 8px;
  margin-right: 8px;
  justify-content: center;
  border-right: 1.5px solid #d8cece;
}
.item-program-selected {
  margin: 0;
  font-family: 'Sora', sans-serif;
  font-size: 15px;
  color: #1e0606;
  font-weight: 800;
  padding-right: 8px;
  margin-right: 8px;
}
.inscription-hero {
  position: absolute;
  margin: -650px 40px 0 40px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 30px;
  height: 80vh;
}
.inscription-block {
  grid-column: 2/8;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 20px auto;
  grid-gap: 30px;
  padding-top: 5%;
  padding-bottom: 17%;
}
.sub-school-menu {
  grid-column: 1/4;
  height: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d8cece;
  padding-bottom: 5px;
}
.place-logo {
  grid-column: 1;
  height: 100%;
  width: 100%;
  padding-top: 170px;
  text-align: center;
}
.place-logo img {
  width: 80%;
}
.place-logo-info {
  grid-column: 1;
  grid-row: 1/4;
  height: 100%;
  width: 100%;
  padding-top: 170px;
  text-align: center;
}
.place-logo-info img {
  width: 80%;
}
.inscription-form {
  grid-column: 1/6;
  display: grid;
}
.form-first-col {
  grid-column: 1/2;
}
.inscription-send {
  display: flex;
  margin-top: 16px;
  text-align: right;
  gap: 10px;
}
.inscription-title {
  grid-column: 1/3;
  margin-top: -22px;
}
.inscription-title p {
  margin-top: 8px;
  color: #1e0606;
  font-size: 0.9em;
}
.thanks-message {
  grid-column: 1/3;
  padding-bottom: 85%;
  margin-top: -22px;
}
.thanks-message p {
  color: #1e0606;
  padding-top: 5px;
}
.school-label {
  color: #1e0606;
  font-weight: 500;
}
.subtitle-school-form {
  font-size: 0.9em;
  margin: 8px 0 16px 4px;
  color: #1e0606;
}
.input-checkbox {
  display: flex;
  justify-content: left;
  align-items: center;
}
.selection-box {
  grid-column: 3/5;
}
.social-claim {
  grid-column: 2/6;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.social-text {
  grid-column: 2/4;
  padding-top: 60px;
  color: #9b2743;
  font-weight: 800;
  padding-left: 20px;
}
.social-text-info {
  color: #9b2743;
  font-weight: 800;
  margin-top: 10px;
}
.social-school-box {
  display: flex;
  column-gap: 20px;
  grid-column: 4/7;
  padding-top: 60px;
}
.social-box-info {
  display: flex;
  column-gap: 20px;
  grid-column: 3;
  padding: 30px 0 20px 0;
}
.icon-circle-bordeaux {
  background-color: #9b2743;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
}
.social-school-icon {
  color: #fff;
  width: 28px;
  height: 28px;
  padding: 6px 0 0 6px;
}
.social-school-icon-big {
  color: #fff;
  width: 30px;
  height: 30px;
  padding: 5px 0 0 5px;
}
.social-text-info-mobile {
  color: #fff;
  text-align: center;
  margin-top: 30px;
  font-size: 16px;
}

@media only screen and (min-width: 1440px) {
  .info-hero {
    padding-bottom: 5%;
  }
  .info-text {
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 10%;
  }
}
@media only screen and (min-width: 1023px) and (max-width: 1261px) {
  .school-hero {
    margin: -44% 40px 0 0px;
  }
  .school-title h1 {
    font-size: 3.8em;
  }
  .info-text {
    font-size: 11px;
  }
  .info-title {
    font-size: 24px;
  }
  .course-title-block p {
    font-size: 12px;
  }
  .course-title-block h2 {
    font-size: 1em;
  }
  .sub-school-menu {
    grid-column: 1/5;
  }
  .inscription-title {
    grid-column: 1/5;
  }
  .inscription-form {
    grid-column: 1/7;
  }
  .selection-box {
    grid-column: 4/7;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .school-background {
    min-height: 500px;
    max-height: 700px;
  }
  .hexa-video {
    min-height: 500px;
    max-height: 700px;
  }
  .school-hero {
    margin: -54% 40px 0 0px;
  }
  .place-logo-info {
    padding-top: 120px;
  }
  .school-title h1 {
    font-size: 3em;
  }
  .info-backgound {
    height: 60vh;
  }
  .program-backgound {
    min-height: 120vh;
  }
  .info-hero {
    padding-top: 125px;
  }

  .info-text {
    grid-column: 3/8;
    font-size: 10px;
    margin-bottom: -80px;
  }
  .info-block {
    grid-column: 2/7;
    margin-top: -22%;
  }
  .info-title {
    font-size: 18px;
  }
  .courses-block {
    grid-column: 2/7;
    margin-top: -55%;
    row-gap: 10px;
    height: 200px;
  }
  .course-title-block p {
    font-size: 12px;
  }
  .course-title-block h2 {
    font-size: 0.9em;
  }
  .video-youtube {
    grid-column: 5/8;
    margin-bottom: 11%;
  }
  .program-title {
    margin-top: 20px;
  }
  .program-block {
    grid-column: 2/7;
  }
  .program-block2 {
    grid-column: 2/7;
    margin-top: -34px;
  }
  .inscription-backgound {
    min-height: 120vh;
  }
  .info-background-long {
    background: linear-gradient(
        270deg,
        #ffffff 4.75%,
        rgba(255, 255, 255, 0) 95.88%
      ),
      url(../../../public/img/hegagonoscompleto.jpg);
    background-size: cover;
    height: 150vh;
    width: 100vw;
  }
  .inscription-title {
    grid-column: 1/5;
    font-size: 1em;
  }
  .selection-box {
    grid-column: 1/5;
  }
  .inscription-form {
    grid-column: 1/7;
  }
  .sub-school-menu {
    grid-column: 1/5;
  }
  .social-text {
    grid-column: 2/5;
    padding-top: 40px;
  }
  .social-school-box {
    grid-column: 5/8;
    padding-top: 40px;
  }

  .icon-circle-bordeaux {
    width: 36px;
    height: 36px;
  }
  .social-text-info {
    padding-top: 10px;
    width: 230px;
  }
  .social-claim {
    grid-row: 4;
    grid-column: 2/7;
    margin-top: -130%;
    justify-content: space-between;
  }
  .social-box-info {
    padding-top: 20px;
  }
  .icon-circle-bordeaux {
    width: 42px;
    height: 42px;
    padding: 1px;
    box-sizing: border-box;
  }
}
@media only screen and (min-width: 330px) and (max-width: 768px) {
  .hexa-video-mobile {
    object-fit: cover;
    width: 100vw;
    overflow: hidden;
  }
  .school-mobile-logo-box {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    padding-right: 10px;
    margin-top: -540px;
    text-align: right;
  }
  .school-mobile-logo-box img {
    width: 40%;
  }
  .school-mobile-title {
    margin-top: 0;
    color: #1e0606;
    font-size: 3em;
    z-index: 20;
    text-align: left;
    padding-left: 20px;
  }
  .school-button-box {
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    gap: 10px;
  }
  .info-school-mobile-box {
    padding: 290px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
  }
  .info-school-mobile-box img {
    width: 30%;
  }
  .info-school-mobile-text {
    padding: 20px 20px 40px 20px;
  }
  .info-school-mobile-text p {
    color: #1e0606;
    font-size: 16px;
    line-height: 22px;
  }
  .bordeax-background {
    background-color: #9b2743;
    padding: 20px 20px 40px 20px;
  }
  .bordeax-background h5 {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    padding-bottom: 24px;
  }
  .course-mobile p {
    color: #fff;
  }
  .course-mobile h2 {
    color: #fff;
    padding-bottom: 24px;
  }
  .inscription-school-mobile-box {
    padding: 290px 20px 0 20px;
  }
  .inscription-school-mobile-box img {
    width: 30%;
  }
  .logo-school-box-mobile {
    width: 100%;
    text-align: right;
    margin-bottom: -50px;
  }

  .inscription-title {
    font-size: 0.7em;
    margin-bottom: 5px;
  }
  .subtitle-school-form {
    margin-top: 36px;
  }
  .inscription-send {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  .thanks-message {
    width: 77%;
  }
  .final-buttons-box {
    display: flex;
    justify-content: left;
    padding-top: 35%;
    gap: 10px;
  }
  .info-backgound {
    background: url(../../../public/img/MobileImages/HexafotoMobile.jpg);
    background-size: cover;
    min-height: 97vh;
    width: 100vw;
    margin-bottom: -370px;
  }
  .en-mobile-title {
    margin-top: -75px;
    color: #1e0606;
    font-size: 2.9em;
    z-index: 20;
    text-align: left;
    padding-left: 20px;
  }
  .net-error-mobile {
    background-color: #9b2743;
    color: #fff;
    margin-bottom: 20px;
    padding: 4px;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
  }
}
