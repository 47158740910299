.dark-button{
    margin-right: 20px;
    background: rgba(0, 0, 0, 0.3);
    border: 1.5px solid #fff;
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 160px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: #fff;
}
.dark-button:hover{
    background: rgba(255, 255, 255, 0.4);
    font-weight: 800;
    color: #9B2743;
    border: 1.5px solid #9B2743;   
}
.dark-button-home{
    margin-right: 20px;
    background: rgba(0, 0, 0, 0.3);
    border: 1.5px solid #fff;
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 300px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
}
.dark-button-home:hover{
    background: rgba(255, 255, 255, 0.6);
    font-weight: 800;
    color: #9B2743;
    border: 2px solid #9B2743;   
}
.light-button{
    margin-right: 20px;
    background: rgba(217, 217, 217, 0.3);
    border: 1.5px solid #9B2743;
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 160px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: #9B2743;
}
.light-button:hover{
    background: #9B2743;
    font-weight: 800;
    color: #fff;
    border: 1.5px solid #D8CECE;
}

.school-button{
    margin-right: 20px;
    background: rgba(217, 217, 217, 0.3);
    border: 1.5px solid #1E0606;
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 160px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: #1E0606;
}

.school-button:hover{
    background: rgba(255, 255, 255, 0.3);
    font-weight: 800;
    color: #9B2743;
    border: 1.5px solid #9B2743;
}
.inscription-button{
    background: rgba(217, 217, 217, 0.3);
    border: 1.5px solid #1E0606;
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 160px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: #1E0606;
}
.inscription-button:hover{
    background: rgba(255, 255, 255, 0.3);
    font-weight: 800;
    color: #9B2743;
    border: 1.5px solid #9B2743;
}
.contact-dark-button{
    background: rgba(0, 0, 0, 0.3);
    border: 1.5px solid #fff;
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 160px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: #fff;
   

}
.contact-dark-button:hover{
    background: rgba(255, 255, 255, 0.6);
    font-weight: 800;
    color: #9B2743;
    border: 1.5px solid #9B2743; 
}
.contact-dark-button:disabled{
    background: rgba(255, 255, 255, 0.4);
    font-weight: 800;
    color: #d8cece;
    border: 1.5px solid #d8cece; 
}
.small-button{
    background: rgba(0, 0, 0, 0.3);
    border: 1.5px solid #fff;
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 16px;
    width: 160px;
    height: 30px;
    font-family: 'Sora', sans-serif;
    font-size: 10px;
    font-weight: 400;
    color: #fff;

}
.rent-button{
    background: rgba(217, 217, 217, 0.3);
    border: 1.5px solid #9B2743;
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 160px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: #9B2743;
}
.rent-button:hover{
    background: #9B2743;
    font-weight: 800;
    color: #fff;
    border: 1.5px solid #D8CECE;
}
.director-button{
    background: rgba(217, 217, 217, 0.5);
    border: 1.5px solid #9B2743;
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 160px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: #9B2743;
}
.director-button:hover{
    background: #9B2743;
    font-weight: 800;
    color: #fff;
    border: 1.5px solid #D8CECE;
}
.rent-button-secondary{
    background: rgba(217, 217, 217, 0.3);
    border: 1.5px solid  rgba(255, 255, 255, 0.7);
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 175px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.7);
}
.rent-button-secondary:hover{
    background:  rgba(217, 217, 217, 0.3);
    font-weight: 800;
    color: #fff;
    border: 1.5px solid #fff;
}
.rent-button-secondary-en{
    background: rgba(217, 217, 217, 0.3);
    border: 1.5px solid  rgba(255, 255, 255, 0.7);
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 160px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.7);
}
.rent-button-secondary-en:hover{
    background:  rgba(217, 217, 217, 0.3);
    font-weight: 800;
    color: #fff;
    border: 1.5px solid #fff;
}
.rent-button-secondary-mobile{
    background: rgba(217, 217, 217, 0.3);
    border: 1.5px solid  rgba(255, 255, 255, 0.7);
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 180px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.7);
}
.small-button-call{
    background-color: #9B2743;
    border: 1px solid #fff;
    border-radius: 16px;
    height: 24px;
    font-family: 'Sora', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    margin: 4px 0 2px -1px;
    letter-spacing: .5px ;
    box-shadow: 2px 2px 3px 2px #00000026;
    padding: 3px 10px;
}
.small-button-call-mobile{
    background-color: #fff;
    border: 2px solid #9B2743;
    border-radius: 16px;
    height: 24px;
    font-family: 'Sora', sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #9B2743;
    margin: 2px 0 2px 5px;
    letter-spacing: .5px ;
}
.learn-button{
    margin-right: 20px;
    background: rgba(0, 0, 0, 0.3);
    border: 1.5px solid #fff;
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 160px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
}
.inscription-cancel-button{
    background: rgba(255, 255, 255, 0.3);
    border: 1.5px solid #b7b7b7;
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 160px;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: #b7b6b6;

}
.inscription-cancel-button:hover{
    border: 1.5px solid #1E0606;
    color: #1E0606;
}
.upload-button{
    float: right;
    margin: 4px 0 2px 0;
    border: 1px solid #fff;
    background-color: #1E0606;
    color: #fff;
    border-radius: 15px;
    padding: 3px 10px;
    font-weight: 600;   
}
.upload-button:hover{
    float: right;
    margin: 4px 0 2px 0;
    border: 1px solid #fff;
    background-color: #9B2743;
    color: #fff;
    border-radius: 15px;
    padding: 3px 10px;
    font-weight: 600;   
}
.upload-button-mobile{
    float: right;
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    border-radius: 15px;
    padding: 3px 10px;
    font-weight: 600;   
    font-size: 12px;
    margin-top: 3px;
}
.file-button{
    float: right;
    margin: 4px 0 2px 0;
    border: 1px solid #9B2743;
    background: rgba(255, 255, 255, 0.4);
    color: #9B2743;
    border-radius: 15px;
    padding: 3px 10px;
    font-weight: 600;   
}
.file-button:hover{
    float: right;
    margin: 4px 0 2px 0;
    border: 1px solid #fff;
    background-color: #9B2743;
    color: #fff;
    border-radius: 15px;
    padding: 3px 10px;
    font-weight: 600;   
}


@media only screen and (min-width: 330px) and (max-width: 768px){
    .contact-dark-button{
        background-color: transparent;
    }
    .small-bordeaux{
        background: #9B2743;
    border: 1.5px solid #fff;
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 16px;
    width: 160px;
    height: 30px;
    font-family: 'Sora', sans-serif;
    font-size: 10px;
    font-weight: 400;
    color: #fff;
    margin-top: 12px;
    }
}